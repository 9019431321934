import {useLocation} from "react-router-dom";

const Play = () => {
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const serverId = queryParams.get('server_id');
    const serverUrl = localStorage.getItem("game_url");
    localStorage.removeItem(serverUrl);

    return (<div>

        <div className="flex items-center justify-center min-h-screen bg-[url('/public/img/game/table.jpg')] bg-center bg-no-repeat">
            <div className="flex items-center justify-center max-w-[1000px] max-h-[600px] mt-12 ml-">
                <object classID="clsid:d27cdb6e-ae6d-11cf-96b8-444553540000" id="7road-ddt-game"
                        codebase="http://fpdownload.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=8,0,0,0"
                        name="Main" width="1000" height="600" align="middle" id="Main">
                    <param name="allowScriptAccess" value="always"/>
                    <param name="movie"
                           value={serverUrl}/>
                    <param name="quality" value="high"/>
                    <param name="menu" value="false"/>
                    <param name="bgcolor" value="#000000"/>
                    <param name="allowScriptAccess" value="always"/>
                    <param name="wmode" value="direct"/>
                    <param name="FlashVars" value="site=&sitename=&rid=&enterCode=&sex="/>
                    <embed flashVars="site=&sitename=&rid=&enterCode=&sex="
                           src={serverUrl}
                           width="1000" height="600" align="middle" quality="high" name="Main"
                           allowscriptaccess="always" type="application/x-shockwave-flash"
                           pluginspage="http://www.macromedia.com/go/getflashplayer"
                           wmode="direct"/>
                </object>
            </div>
        </div>

    </div>);
};

export default Play;