import SimpleHeader from "../components/simple/simple-header";
import SimpleFooter from "../components/simple/simple-footer";
import SimpleLogin from "../components/simple/simple-login";
import SimpleNotice from "../components/simple/simple-notice";
import SimplePlay from "../components/simple/simple-play";

const Home = () => {
    return (
        <div>
            <SimpleHeader></SimpleHeader>

            <main
                className="flex-1 bg-[url('/public/img/bg.png')] bg-cover bg-center  mx-auto h-screen flex flex-col items-center justify-center">
                <div className="flex-none mb-8 mt-4">
                    <img src="/img/logo.png"
                         className="h-auto hidden sm:block max-w-[100px] md:max-w-[200px] lg:max-w-[600px]"
                         alt="big-logo"></img>
                </div>

                <div className="flex flex-col sm:flex-row gap-4">
                    {/* Card de login */}
                    <div className="flex-1 bg-white rounded-[60px] sm:min-w-[280px] min-h-[400px] md:min-w-[350px] lg:min-h-[400px] shadow-lg flex-shrink-0">
                        {
                            localStorage.getItem("token") ? (<SimplePlay/>) : (<SimpleLogin/>)
                        }
                    </div>

                    {/* Card de notícias */}
                    <div
                        className="flex-1 bg-white rounded-[60px] sm:min-w-[280px] mb-10 md:min-w-[460px] lg:min-w-[600px] h-[400px] shadow-lg flex-shrink-0">
                        <SimpleNotice/>
                    </div>
                </div>

            </main>

            <SimpleFooter></SimpleFooter>
        </div>
    );
}

export default Home;