import axios from "axios";
import {useEffect, useState} from "react";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";

const SimplePlay = () => {
    const navigate = useNavigate();
    const [servers, setServers] = useState([]);
    const [selectedServer, setSelectedServer] = useState(null);  // Estado para o servidor selecionado
    const [lastSelectedServer, setLastSelectedServer] = useState(localStorage.getItem("last_server") ? JSON.parse(localStorage.getItem("last_server")) : null);

    let user = JSON.parse(localStorage.getItem("user"));

    const logoutClick = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("last_server");

        window.location = "./";
    };

    useEffect(() => {
        const fetchServers = async () => {
            try {
                let response = await axios.get(`${process.env.REACT_APP_API_URL}v1/server/list`);

                setServers(response.data.data.servers);
            } catch (err) {
                await Swal.fire("", "Error fetching servers.", "error");
            }
        };

        fetchServers();
    }, []);

    const handleServerChange = (e) => {
        setSelectedServer(e.target.value);  // Atualiza o servidor selecionado
    };

    const playBtnClick = async (e) => {
        e.preventDefault();

        if (!selectedServer) {
            await Swal.fire("", "Nenhum servidor selecionado!", "error");
            return;
        }

        let server = servers.find((server) => server.id === parseInt(selectedServer));
        if (server.online === "0") {
            await Swal.fire("", "O servidor não está on-line!", "warning");
            return;
        }

        Swal.showLoading();

        try {
            const formDataToSend = new FormData();
            formDataToSend.append("server_id", server.id);

            let response = await axios.post(`${process.env.REACT_APP_API_URL}v1/logged/server/play`, formDataToSend, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });

            let gameToken = response.data.data.token;
            let lastServer = response.data.data.last_server;
            localStorage.setItem("last_server", JSON.stringify(lastServer));
            setLastSelectedServer(lastServer);

            const {value: escolha, isConfirmed, isDenied, isDismissed} = await Swal.fire({
                title: 'Como deseja jogar?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Launcher 337/Gunny',
                showDenyButton: true,
                denyButtonText: 'Navegador atual',
                showCloseButton: true,
                cancelButtonText: 'Outros',
                footer: `
                    <div class="flex flex-col mb-2">
                      <span class="text-green-900">Agora é possível jogar pelo celular!</span>
                      <span> Selecione para gerar o QRCode!</span>
                    </div>
                    <div class="flex gap-2">
                      <a href="https://downloads.ddtankarena.com/" class="bg-[#31913d] px-2 py-2 text-white rounded w-full">Android</a>          
                    </div>
                  `,
                customClass: {
                    confirmButton: 'bg-[#581c08]',  // Classe personalizada para o botão de "Launcher"
                    denyButton: 'bg-[#85351a]',     // Classe personalizada para o botão de "Navegador Atual"
                    cancelButton: 'bg-[#d7724f]',   // Classe personalizada para o botão de "Nenhum"
                    icon: 'rounded-full border-[2px]',
                },
                iconHtml: '<img src="/img/crazy-chicken.webp?v=3" alt="Ícone personalizado" class="h-50 rounded-full" />'
            });

            if (isConfirmed) {
                try {
                    let gameUrl = `${server.flash_url}Loading.swf?user=${user.email}&key=${gameToken}&isGuest=False&ua=&v=10991&rand=1723341553&config=${server.flash_url}config1.xml`;
                    window.location = `roadclient://${gameUrl}`;
                } catch(error) {
                    console.log(error);
                }
            } else if (isDenied) {
                let gameUrl = `${server.flash_url}Loading.swf?user=${user.email}&key=${gameToken}&isGuest=False&ua=&v=10991&rand=1723341553&config=${server.flash_url}config1.xml`;
                localStorage.setItem("game_url", gameUrl);

                window.open("/play?server_id=" + server.id, "_blank");
            } else if (isDismissed) {
                window.open("https://downloads.ddtankarena.com/", "_blank");
            }


            // let gameUrl = `${server.flash_url}Loading.swf?user=${user.email}&key=${gameToken}&isGuest=False&ua=&v=10991&rand=1723341553&config=${server.flash_url}config1.xml`;
            // window.location = `roadclient://${gameUrl}`;
            // //window.open("/play?server_id=" + server.id + "&token=" + gameToken, "_blank");
            //
            // await Swal.fire("", "O jogo está sendo aberto!", "info");
        } catch (err) {
            await Swal.fire("", err.response.data.message, "error");
        } finally {
            Swal.hideLoading();
        }
    }

    const centerBtnClick = () => {
        navigate("/dashboard/home");
    };

    return (<div>
        <div className="flex items-center bg-[#67240e] text-white rounded-t-[30px] py-4 px-6">
            <img className="h-[40px] transform -scale-x-100" src="/img/character_3.png"
                 alt="login_character"/>
            <h2 className="text-xl text-[#ed9071] font-bold uppercase pl-4">Central Rápida</h2>
        </div>

        <div className="mt-4 pr-4 pl-4">
            <div className="flex justify-between">

                <div className="flex">
                    <img className="rounded-[15px]" src={user.profile_photo_url} alt={"Profile Photo"}></img>
                    <div className="flex flex-col pl-2 mt-2">
                        <span className="font-sans font-bold text-[#67240e]">{user.name.substring(0, 9)}</span>
                        <span className="text-[#916151]">Novato</span>
                    </div>
                </div>

                <div className="flex items-center gap-2">
                    <button className="hover:bg-[#bb7442] bg-[#fea05e] text-white rounded-[15px] px-4 py-2"
                            onClick={centerBtnClick}>Central
                    </button>
                    <button className="hover:bg-[#77331d] bg-[#ab4827] text-white rounded-[15px] px-4 py-2"
                            onClick={logoutClick}>Sair
                    </button>
                </div>

            </div>

            <div className="flex flex-col mt-4">
                <span className="text-[#916151] font-sans font-bold">Último servidor acessado:</span>

                {
                    lastSelectedServer ?
                        (
                            <button
                                className="hover:bg-[#411d1c] bg-[#633b2e] px-2 py-2 rounded border border-[#7c4a48] focus:outline-none focus:ring-2 focus:ring-[#ffccbc] text-white text-left">
                                {lastSelectedServer.name}
                            </button>
                        )
                        :
                        (
                            <div
                                className="bg-[#916151] px-2 py-2 rounded border border-[#7c4a48] focus:outline-none focus:ring-2 focus:ring-[#ffccbc] text-white">
                                Você ainda não acessou um servidor!
                            </div>
                        )
                }
            </div>

            <div className="bg-[#67240e] w-full mt-4 mb-4 py-[0.1px]"></div>

            <div className="flex flex-col mt-4">
                <span className="text-[#916151] font-sans font-bold">Lista de servidores:</span>

                <select
                    className="bg-[#916151] text-white px-4 py-2 rounded border border-[#7c4a48] focus:outline-none focus:ring-2 focus:ring-[#ffccbc]"
                    onChange={handleServerChange}  // Evento onChange para capturar a seleção
                >
                    <option value="" disabled selected className="text-gray-400">Selecione para jogar</option>
                    {servers.map(server => (
                        <option key={server.id} value={server.id}>{server.name}</option>
                    ))}
                </select>
            </div>

            <div className="flex flex-col items-center mt-2">
                <button
                    className={`hover:bg-[#792408] bg-[#932e0d] px-2 py-2 rounded-b-[25px] w-full text-white font-sans ${!selectedServer ? "disabled:bg-[#b96d55] disabled:cursor-not-allowed" : ""}`}
                    disabled={!selectedServer} onClick={playBtnClick}>
                    Jogar
                </button>
            </div>
        </div>
    </div>);
}

export default SimplePlay;
