import {useNavigate} from "react-router-dom";
import axios from "axios";
import {useState} from "react";
import Swal from "sweetalert2";

const SimpleRegister = () => {
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSignInClick = () => {
        navigate('/');
    };

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirm_password: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('password', formData.password);
        formDataToSend.append("password_confirmation", formData.confirm_password);
        if(localStorage.getItem("ref") != null && localStorage.getItem("ref") != '') {
            formDataToSend.append("ref", localStorage.getItem("ref"));
        }

        Swal.showLoading();
        try {
            let response = await axios.post(`${process.env.REACT_APP_API_URL}v1/auth/register`, formDataToSend, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            await Swal.fire("", response.data.message, "success");

            navigate('/');
        } catch (err) {
            await Swal.fire("", err.response.data.message, "error");
        } finally {
            Swal.hideLoading();
        }
    };

    return (
        <div className="">
            <div className="flex items-center bg-[#67240e] text-white rounded-t-[30px] py-4 px-6">
                <img className="h-[40px] transform -scale-x-100" src="/img/character_3.png"
                     alt="login_character"/>
                <h2 className="text-xl text-[#ed9071] font-bold uppercase pl-4">Cadastro</h2>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="flex flex-col py-4 px-6">
                    <div>
                        <label htmlFor="name" className="text-[#67240e] font-bold">
                            Nome
                        </label>

                        <input
                            className="focus:outline-none bg-[#67240e40] hover:bg-[#67240e69] w-full rounded-[15px] px-4 py-2 placeholder-[#67240e]"
                            id="name" name="name" type="text" placeholder="Insira seu nome" value={formData.name}
                            onChange={handleChange}/>
                    </div>

                    <div className="mt-4">
                        <label htmlFor="email" className="text-[#67240e] font-bold">Endereço de e-mail</label>
                        <input
                            className="focus:outline-none bg-[#67240e40] hover:bg-[#67240e69] w-full rounded-[15px] px-4 py-2 placeholder-[#67240e]"
                            id="email" name="email" type="text" placeholder="Insira seu e-mail" value={formData.email}
                            onChange={handleChange}/>
                    </div>

                    <div className="mt-4">
                        <label htmlFor="password" className="text-[#67240e] font-bold">Senha</label>
                        <input
                            className="focus:outline-none bg-[#67240e40] hover:bg-[#67240e69] w-full rounded-[15px] px-4 py-2 placeholder-[#67240e]"
                            id="password" name="password" type="password" placeholder="Senha" value={formData.password}
                            onChange={handleChange}/>
                    </div>

                    <div className="mt-4">
                        <label htmlFor="confirm_password" className="text-[#67240e] font-bold">Confirme sua
                            senha</label>
                        <input
                            className="focus:outline-none bg-[#67240e40] hover:bg-[#67240e69] w-full rounded-[15px] px-4 py-2 placeholder-[#67240e]"
                            id="confirm_password" name="confirm_password" type="password"
                            placeholder="Confirmação de senha" value={formData.confirm_password}
                            onChange={handleChange}/>
                    </div>
                </div>

                <div className="flex justify-center gap-2 p-5">
                    <button className="btn-light-brown-red rounded-[20px] text-white w-1/2" onClick={handleSignInClick}>
                        Voltar
                    </button>
                    <button className="btn-brown-red rounded-[20px] text-white w-1/2" type="submit">
                        Finalizar cadastro
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SimpleRegister;