const SimpleFooter = () => {
    return (<footer className="bg-[#833014] py-6 text-[#ed9071] mx-auto bottom-0">
        <div className="max-w-[1920px] mx-auto px-4">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
                <div></div>

                <div className="mb-4 md:mb-0">
                    <h3 className="text-lg font-bold mb-2">Links Úteis</h3>
                    <ul>
                        <li><a href="#" className="hover:underline">Página Inicial</a></li>
                        <li><a href="apresentacao/index.html" className="hover:underline">Sobre Nós</a></li>
                        <li><a href="#" className="hover:underline">Termos e Condições</a></li>
                        <li><a href="#" className="hover:underline">Política de Privacidade</a></li>
                    </ul>
                </div>

                <div className="mb-4 md:mb-0">
                    <h3 className="text-lg font-bold mb-2">Contato</h3>
                    <ul>
                        <li><a href="mailto:contato@ddtankarena.com"
                               className="hover:underline">contato@ddtankarena.com</a></li>
                        <li><a href="tel:+5562920022878" className="hover:underline">+55 62 92002-2878</a></li>
                    </ul>
                </div>

                <div></div>
            </div>
        </div>
    </footer>);
}

export default SimpleFooter;