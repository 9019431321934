import SimpleHeader from "../components/simple/simple-header";
import SimpleFooter from "../components/simple/simple-footer";
import SimpleNotice from "../components/simple/simple-notice";
import SimpleRegister from "../components/simple/simple-register";
import { useLocation } from "react-router-dom";

const Register = () => {
    const location = useLocation();

    // Função para extrair parâmetros da URL
    const getQueryParam = (param) => {
      const urlParams = new URLSearchParams(location.search);
      return urlParams.get(param);
    };
  
    // Pega o valor do parâmetro 'ref'
    const refParam = getQueryParam('ref');

    if(refParam != '') {
        localStorage.setItem("ref", refParam);
    }

    return (
        <div>
            <SimpleHeader></SimpleHeader>

            <main className="flex-1 bg-[url('/public/img/bg.png')] bg-cover bg-center  mx-auto h-screen flex flex-col items-center justify-center">
                <div className="flex-none mb-8 sm:mb-[30px] md:mt-4 lg:mt-4">
                    <img src="/img/logo.png"
                         className="h-auto hidden sm:block max-w-[100px] md:max-w-[200px] lg:max-w-[600px]"
                         alt="big-logo"></img>
                </div>

                <div className="flex flex-col sm:flex-row mt-[50px] mb-[90px] gap-4">
                    {/* Card de login */}
                    <div className="flex-1 bg-white rounded-[60px] sm:min-w-[280px] md:min-w-[350px] lg:min-h-[400px] shadow-lg flex-shrink-0">
                        <SimpleRegister/>
                    </div>

                    {/* Card de notícias */}
                    <div
                        className="flex-1 bg-white rounded-[60px] sm:min-w-[280px] md:min-w-[460px] lg:min-w-[600px] h-[400px] shadow-lg flex-shrink-0">
                        <SimpleNotice/>
                    </div>
                </div>

            </main>

            <SimpleFooter></SimpleFooter>
        </div>
    );
}

export default Register;