import SimpleDashboardMenu from "../../components/simple/simple-dashboard-menu";
import SimpleFooter from "../../components/simple/simple-footer";
import SimpleHeader from "../../components/simple/simple-header";
import {useEffect, useState} from "react";
import Swal from "sweetalert2";
import axios from "axios";

const DashboardAffiliates = () => {
    const [affiliateList, setAffiliateList] = useState(null);

    useEffect(() => {
        let fetchUserAffiliates = async() => {

            try {

                let affiliateAxios = await axios.get(`${process.env.REACT_APP_API_URL}v1/logged/affiliate/getData`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    }
                });

                let data = affiliateAxios.data.data;
                let affiliates = data.affiliates;
                let totalEarnings = data.totalEarnings;

                setAffiliateList(affiliates);

                console.log(affiliateList);
            } catch (err) {
                await Swal.fire("", "Error fetching data.", "error");
            }

        };

        fetchUserAffiliates();
    }, []);

    return (
        <>
        
            <div className="flex-1 bg-[url('/public/img/bg.png')] bg-cover bg-center">
                <SimpleHeader></SimpleHeader>

                <main className="flex h-screen mx-auto justify-center gap-4 mt-20">
                    <div className="flex-none">
                        <div className="flex flex-wrap gap-4">
                            <SimpleDashboardMenu></SimpleDashboardMenu>

                            <div className="flex-1 bg-white rounded-[60px] min-w-[700px] h-auto shadow-lg flex-shrink-0">
                                <div className="flex items-center bg-[#67240e] text-white rounded-t-[30px] py-4 px-6">
                                    <img className="h-[40px]" src="/img/character_2.png" alt="notice_character"/>
                                    <h2 className="text-xl text-[#ed9071] font-bold uppercase pl-4">Afiliados</h2>
                                </div>

                                <div className="flex justify-center gap-4 px-4 py-4">
                                    <div className="flex flex-col gap-4 w-full">
                                        <div className="flex w-full justify-between">
                                            <div className="flex bg-[#9b3a1a] w-full rounded rounded-r-[30px] z-10 px-4 py-4">
                                                <div className="text-white font-sans uppercase">
                                                    Ganhos Totais: <span className="font-bold">R$ 60,00</span>
                                                </div>
                                            </div>
                                            <div className="flex items bg-red-200 w-full rounded rounded-l-lg -ml-10 px-4 py-4 items-center justify-end">
                                                <svg viewBox="0 0 1024 1024" fill="currentColor" height="1em" width="1em" className="text-[#9b3a1a]">
                                                    <path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z" />
                                                </svg>
                                                <span className="font-bold text-[#9b3a1a]">SPL_023188B8356D</span>
                                            </div>
                                        </div>
                                        
                                        <div className="grid sm:grid-cols-1 md:grid-cols-2 w-full gap-2">
                                           <div className="flex justify-between bg-[#67240e73] px-4 py-4 rounded">
                                                <div className="flex items-center gap-2 font-sans">
                                                    <img src="https://ui-avatars.com/api/?name=X&amp;color=ed9071&amp;background=67240e" className="rounded-full h-12"></img>
                                                    <div className="flex flex-col">
                                                        <div className="font-bold text-[#67240e] text-lg">José</div>
                                                        <div className="font-bold text-[#934329] uppercase text-sm">Level 1</div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center">
                                                    <div className="bg-[#501e0dcc] px-2 py-1 rounded-full text-white">
                                                        R$ 30,00
                                                    </div>
                                                </div>
                                           </div>     
                                           <div className="flex justify-between bg-[#67240e73] px-4 py-4 rounded">
                                                <div className="flex items-center gap-2 font-sans">
                                                    <img src="https://ui-avatars.com/api/?name=L&amp;color=ed9071&amp;background=67240e" className="rounded-full h-12"></img>
                                                    <div className="flex flex-col">
                                                        <div className="font-bold text-[#67240e] text-lg">Lucas</div>
                                                        <div className="font-bold text-[#934329] uppercase text-sm">Level 2</div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center">
                                                    <div className="bg-[#501e0dcc] px-2 py-1 rounded-full text-white">
                                                        R$ 20,00
                                                    </div>
                                                </div>
                                           </div>    
                                           <div className="flex justify-between bg-[#67240e73] px-4 py-4 rounded">
                                                <div className="flex items-center gap-2 font-sans">
                                                    <img src="https://ui-avatars.com/api/?name=G&amp;color=ed9071&amp;background=67240e" className="rounded-full h-12"></img>
                                                    <div className="flex flex-col">
                                                        <div className="font-bold text-[#67240e] text-lg">Gabriel</div>
                                                        <div className="font-bold text-[#934329] uppercase text-sm">Level 3</div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center">
                                                    <div className="bg-[#501e0dcc] px-2 py-1 rounded-full text-white">
                                                        R$ 10,00
                                                    </div>
                                                </div>
                                           </div>    
                                           <div className="flex justify-between bg-[#67240e73] px-4 py-4 rounded">
                                                <div className="flex items-center gap-2 font-sans">
                                                    <img src="https://ui-avatars.com/api/?name=M&amp;color=ed9071&amp;background=67240e" className="rounded-full h-12"></img>
                                                    <div className="flex flex-col">
                                                        <div className="font-bold text-[#67240e] text-lg">Manoel</div>
                                                        <div className="font-bold text-[#934329] uppercase text-sm">Level 3</div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center">
                                                    <div className="bg-[#501e0dcc] px-2 py-1 rounded-full text-white">
                                                        R$ 0,00
                                                    </div>
                                                </div>
                                           </div>    
                                           <div className="flex justify-between bg-[#67240e73] px-4 py-4 rounded">
                                                <div className="flex items-center gap-2 font-sans">
                                                    <img src="https://ui-avatars.com/api/?name=R&amp;color=ed9071&amp;background=67240e" className="rounded-full h-12"></img>
                                                    <div className="flex flex-col">
                                                        <div className="font-bold text-[#67240e] text-lg">Rafael</div>
                                                        <div className="font-bold text-[#934329] uppercase text-sm">Level 4</div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center">
                                                    <div className="bg-[#501e0dcc] px-2 py-1 rounded-full text-white">
                                                        R$ 0,00
                                                    </div>
                                                </div>
                                           </div>    
                                           <div className="flex justify-between bg-[#67240e73] px-4 py-4 rounded">
                                                <div className="flex items-center gap-2 font-sans">
                                                    <img src="https://ui-avatars.com/api/?name=K&amp;color=ed9071&amp;background=67240e" className="rounded-full h-12"></img>
                                                    <div className="flex flex-col">
                                                        <div className="font-bold text-[#67240e] text-lg">Kauan</div>
                                                        <div className="font-bold text-[#934329] uppercase text-sm">Level 5</div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center">
                                                    <div className="bg-[#501e0dcc] px-2 py-1 rounded-full text-white">
                                                        R$ 0,00
                                                    </div>
                                                </div>
                                           </div>                     
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </main>

                <SimpleFooter></SimpleFooter>
            </div>

        </>
    );
};

export default DashboardAffiliates;