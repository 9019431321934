import SimpleHeader from "../../components/simple/simple-header";
import SimpleDashboardMenu from "../../components/simple/simple-dashboard-menu";
import SimpleFooter from "../../components/simple/simple-footer";

const DashboardMessages = () => {
    return (
        <div className="flex-1 bg-[url('/public/img/bg.png')] bg-cover bg-center">
            <SimpleHeader></SimpleHeader>

            <main className="flex h-screen mx-auto justify-center gap-4 mt-20">
                <div className="flex-none">
                    <div className="flex flex-wrap gap-4">
                        <SimpleDashboardMenu></SimpleDashboardMenu>

                        <div className="flex-1 bg-white rounded-[60px] min-w-[700px] h-[400px] shadow-lg flex-shrink-0">

                            <div className="flex items-center bg-[#67240e] text-white rounded-t-[30px] py-4 px-6">
                                <img className="h-[40px]" src="/img/character_2.png" alt="notice_character"/>
                                <h2 className="text-xl text-[#ed9071] font-bold uppercase pl-4">Minhas Mensagens</h2>
                            </div>

                            <div className="flex justify-between">
                                Mensagens
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <SimpleFooter></SimpleFooter>
        </div>
    );
};

export default DashboardMessages;