import * as React from "react";
import { createRoot } from "react-dom/client";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import Home from './pages/home';
import Register from "./pages/register";
import Play from "./pages/play";
import ForgotPassword from "./pages/forgotPassword";
import DashboardHome from "./pages/dashboard/dashboard-home";
import DashboardAccount from "./pages/dashboard/dashboard-account";
import DashboardWallet from "./pages/dashboard/dashboard-wallet";
import DashboardMessages from "./pages/dashboard/dashboard-messages";
import DashboardDDTMoney from "./pages/dashboard/dashboard-ddt-money";
import DashboardWaitPayment from "./pages/dashboard/dashboard-wait-payment";
import LauncherHome from "./launcher-pages/launcher-home";
import WebTest from "./pages/webtest";
import LauncherLogin from "./launcher-pages/launcher-login";
import LauncherRegister from "./launcher-pages/launcher-register";
import LauncherLoggedHome from "./launcher-pages/launcher-logged-home";
import DashboardAffiliates from "./pages/dashboard/dashboard-affiliates";
import NovoServidorRegister from "./novo-servidor/register";
import NovoServidorPresentation from "./novo-servidor/presentation";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home></Home>} />
                <Route path="/sign-up" element={<Register></Register>} />
                <Route path="/forgotPassword" element={<ForgotPassword></ForgotPassword>} />
                {/*<Route path="/recharge" element={<DashboardHome></DashboardHome>} />*/}
                <Route path="/play" element={<Play></Play>} />

                <Route path="/dashboard/home" element={<DashboardHome></DashboardHome>} />
                <Route path="/dashboard/account" element={<DashboardAccount></DashboardAccount>} />
                <Route path="/dashboard/wallet" element={<DashboardWallet></DashboardWallet>} />
                <Route path="/dashboard/messages" element={<DashboardMessages></DashboardMessages>} />
                <Route path="/dashboard/buy-ddt-money" element={<DashboardDDTMoney></DashboardDDTMoney>} />
                <Route path="/dashboard/wait-payment" element={<DashboardWaitPayment></DashboardWaitPayment>} />
                <Route path="/dashboard/affiliates" element={<DashboardAffiliates></DashboardAffiliates>} />

                <Route path="/launcher/home" element={<LauncherHome></LauncherHome>} />
                <Route path="/launcher/login" element={<LauncherLogin></LauncherLogin>} />
                <Route path="/launcher/register" element={<LauncherRegister></LauncherRegister>} />
                <Route path="/launcher/logged" element={<LauncherLoggedHome></LauncherLoggedHome>} />

                <Route path="/novoservidor" element={<NovoServidorRegister></NovoServidorRegister>} />
                <Route path="/presentation" element={<NovoServidorPresentation></NovoServidorPresentation>} />

                <Route path="/pages/test" element={<WebTest></WebTest>} />

                <Route path="*" element={<Home></Home>} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;