import {useNavigate} from "react-router-dom";
import {useState} from "react";
import Swal from "sweetalert2";
import axios from "axios";

const SimpleForgot = () => {
    const [email, setEmail] = useState('');
    const [successEmailSend, setSuccessEmailSend] = useState(false);

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        password_confirmation: '',
        token: ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const handleEmailInputChange = (event) => {
        setEmail(event.target.value);
    };

    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate("/");
    };

    const handleSendEmailClick = async (e) => {
        e.preventDefault();

        Swal.showLoading();
        try {
            const formDataToSend = new FormData();
            formDataToSend.append("email", email);

            let response = await axios.post(`${process.env.REACT_APP_API_URL}v1/auth/forgot/send-email`, formDataToSend);
            let result = response.data;

            if(result.success){
                await Swal.fire("", result.message, "success");
                setSuccessEmailSend(true);
            }
        } catch (err) {
            await Swal.fire("", err.response.data.message, "error");
        } finally {
            Swal.hideLoading();
        }
    };

    const handleChangePasswordClick = async (e) => {
      e.preventDefault();

      Swal.showLoading();
      try {

          const formDataToSend = new FormData();
          formDataToSend.append("email", email);
          formDataToSend.append("token", formData.token);
          formDataToSend.append("password", formData.password);
          formDataToSend.append("password_confirmation", formData.password_confirmation);

          let response = await axios.post(`${process.env.REACT_APP_API_URL}v1/auth/forgot/reset-password`, formDataToSend);
          let result = response.data;

          if(result.success){
              navigate("/");
              await Swal.fire("", result.message, "success");
          }
      } catch (err) {
          await Swal.fire("", err.response.data.message, "error");
      } finally {
          Swal.hideLoading();
      }
    };

    return (<div>
        <div className="flex items-center bg-[#67240e] text-white rounded-t-[30px] py-4 px-6">
            <img className="h-[40px] transform -scale-x-100" src="/img/character_3.png"
                 alt="login_character"/>
            <h2 className="text-xl text-[#ed9071] font-bold uppercase pl-4">Redefinição de senha</h2>
        </div>

        <form onSubmit={handleChangePasswordClick}>
            <div className="flex flex-col gap-4 justify-center items-center my-4 mx-4 h-[300px]">

                <div className="flex w-full justify-center">
                    <input name="email" className="simple-input w-full" placeholder="Endereço de e-mail"
                           onChange={handleEmailInputChange} />
                    <button className="btn-brown-red rounded-r-[30px] px-4 py-2 text-white"
                            onClick={handleSendEmailClick}>Enviar
                    </button>
                </div>
                <div className="flex w-full">
                    <input name="token" className="simple-input disabled:bg-[#67240e9e] w-full rounded-r-[30px]"
                           placeholder="Insira o código recebido no e-mail" disabled={!successEmailSend}
                           onChange={handleChange} value={formData.token}/>
                </div>
                <div className="flex w-full">
                    <input name="password" type={"password"}
                           className="simple-input disabled:bg-[#67240e9e] w-full rounded-r-[30px]"
                           placeholder="Nova senha"
                           disabled={!successEmailSend} onChange={handleChange} value={formData.password}/>
                </div>
                <div className="flex w-full">
                    <input name="password_confirmation" type={"password"}
                           className="simple-input disabled:bg-[#67240e9e] w-full rounded-r-[30px]"
                           placeholder="Confirmação da nova senha"
                           disabled={!successEmailSend} onChange={handleChange} value={formData.password_confirmation}/>
                </div>

                <div className="flex w-full gap-2 justify-center">
                    <div>
                        <button className="btn-light-brown-red rounded-[20px] text-white"
                                onClick={handleBackClick}>Voltar
                        </button>
                    </div>
                    <div>
                        <button className="btn-brown-red rounded-[20px] text-white">Alterar senha</button>
                    </div>
                </div>

            </div>
        </form>

    </div>
);
};

export default SimpleForgot;