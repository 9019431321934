import LauncherMenuBtn from "../components/launcher/launcher-menu-btn";
import {useState} from "react";
import Swal from "sweetalert2";
import axios from "axios";
import LauncherMenu from "./launcher-menu";
import {useNavigate} from "react-router-dom";

const LauncherLogin = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append("email", formData.email);
        formDataToSend.append("password", formData.password);

        Swal.showLoading();
        try {
            let response = await axios.post(`${process.env.REACT_APP_API_URL}v1/auth/login`, formDataToSend, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if(response.data.data.token)
            {
                localStorage.setItem("user", JSON.stringify(response.data.data.user));
                localStorage.setItem("token", response.data.data.token);
                localStorage.setItem("last_server", JSON.stringify(response.data.data.last_server));

                window.location = "/launcher/logged";
                //navigate(window.location.pathname + "?reload=" + new Date().getTime());
            }

            //await Swal.fire("", response.data.message, "success");
        } catch (err) {
            await Swal.fire("", err.response.data.message, "error");
        } finally {
            Swal.hideLoading();
        }
    };

    return (
        <div className="bg-[#732f1c]">
            <div
                className="flex flex-col bg-[url('/public/img/bg.png')] bg-cover bg-center mx-auto h-screen rounded overflow-y-hidden">
                <div className="flex-grow">
                    <div className="flex items-center justify-center h-full">

                        <div className="w-[450px]">
                            <div className="flex items-center bg-[#67240e] text-white rounded-t-[20px] py-4 px-6">
                                <img className="h-[40px] transform -scale-x-100" src="/img/character_3.png"
                                     alt="login_character"/>
                                <h2 className="text-xl text-[#ed9071] font-bold uppercase pl-4">Login</h2>
                            </div>

                            <form onSubmit={handleSubmit}>
                                <div className="flex flex-col bg-white h-[150px] rounded-b-[20px] px-4 py-2">
                                    <div className="flex gap-4 justify-between">

                                        <div className="w-1/2">
                                            <label htmlFor="email" className="text-[#67240e] font-bold">Endereço de
                                                e-mail</label>
                                            <input
                                                className="focus:outline-none bg-[#67240e40] hover:bg-[#67240e69] w-full rounded-[15px] px-4 py-2 placeholder-[#67240e]"
                                                id="email" name="email" type="text" placeholder="Insira seu e-mail" value={formData.email} onChange={handleChange}/>
                                        </div>

                                        <div className="w-1/2">
                                            <label htmlFor="password" className="text-[#67240e] font-bold">Senha</label>
                                            <input
                                                className="focus:outline-none bg-[#67240e40] hover:bg-[#67240e69] w-full rounded-[15px] px-4 py-2 placeholder-[#67240e]"
                                                id="password" name="password" type="password" placeholder="Senha" value={formData.password} onChange={handleChange}/>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <button className="btn-brown-red px-1 py-3 rounded-[20px] text-white w-full">
                                            Entrar
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <LauncherMenu></LauncherMenu>
            </div>
        </div>
    );
};

export default LauncherLogin;