import '../styles/novoservidor.css';
import {useEffect, useState} from "react";
import Swal from "sweetalert2";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const NovoServidorRegister = () => {
    const navigate = useNavigate();

    const [showConfirmBox, setShowConfirmBox] = useState(false);

    const [form, setForm] = useState({
        email: '',
        telephone: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "telephone") {
            let phone = value.replace(/\D/g, '');

            if (phone.length > 11) {
                phone = phone.slice(0, 11);
            }
            if (phone.length > 2) {
                phone = phone.replace(/^(\d{2})(\d)/, '$1 $2');
            }
            if (phone.length > 7) {
                phone = phone.replace(/(\d{5})(\d{4})$/, '$1-$2');
            }

            // Atualiza o estado do form com o número de telefone formatado
            setForm((prevForm) => ({
                ...prevForm,
                [name]: phone // Use o número de telefone formatado aqui
            }));
        } else {
            // Para outros campos, atualiza normalmente
            setForm((prevForm) => ({
                ...prevForm,
                [name]: value
            }));
        }
    };


    async function rescue(event) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailPattern.test(form.email)) {
            await Swal.fire("Atenção", "Por favor, insira um endereço de e-mail válido.", "warning");
        }

        try {
            const request =  await axios.post(`${process.env.REACT_APP_API_URL}v1/new-server/store`, {
                email: form.email,
                telephone: form.telephone
            });

            if (request.status === 200) {
                setShowConfirmBox(true);
            }
        } catch (err) {
            //console.log(err);
            await Swal.fire("", err.response.data.message, "error");
        }
    }

    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        const targetDate = new Date('2024-11-15T19:00:00-03:00'); // Data e horário-alvo no horário de Brasília (GMT-3)

        const updateCountdown = () => {
            const now = new Date();
            const distance = targetDate - now;

            if (distance < 0) {
                setTimeLeft({
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                });
                return;
            }

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            setTimeLeft({ days, hours, minutes, seconds });
        };

        updateCountdown();
        const interval = setInterval(updateCountdown, 1000);

        return () => clearInterval(interval);
    }, []);

    function goPresentation() {
        navigate("/presentation");
    }

    return (
        <>
            <div>
                <header>
                    <div className="header-container">
                        <a href="https://www.youtube.com/watch?v=ulBI4uQvzlk&ab_channel=CamiloJackson">
                            <img src="/img/novoservidor/imagens/minilogo.png" alt="Mini Logo"
                                 className="minilogo"></img>
                        </a>
                        <nav className="desktop-menu">
                            <a href="https://ddtankarena.com/precadastro">
                                <center>
                                    <img src="/img/novoservidor/imagens/ico1.png" alt="Página Inicial"
                                         className={"mb-2"}></img>
                                </center>
                                Página Inicial
                            </a>

                            <a onClick={goPresentation}>
                                <center>
                                    <img src="/img/novoservidor/imagens/ico2.png" alt="Apresentação"
                                         className={"mb-2"}></img>
                                </center>
                                Apresentação
                            </a>

                            <a href="https://ddtankarena.com/">
                                <center>
                                    <img src="/img/novoservidor/imagens/ico3.png" alt="Página Inicial"
                                         className={"mb-2"}></img>
                                </center>
                                Download
                            </a>
                        </nav>
                        <div className="social-icons">
                            <a href="https://chat.whatsapp.com/G0bYZgbFnPV5LXTmiHYtEN"><img
                                src="/img/novoservidor/imagens/ico4.png"
                                alt="WhatsApp"></img></a>
                            <a href="https://www.instagram.com/ddtankarena"><img
                                src="/img/novoservidor/imagens/ico5.png"
                                alt="Instagram"></img></a>
                            <a href="https://www.facebook.com/profile.php?id=61566756633606"><img
                                src="/img/novoservidor/imagens/ico6.png"
                                alt="Facebook"></img></a>
                        </div>
                        <button className="mobile-menu" onClick="toggleMobileMenu()">☰</button>
                    </div>
                    <div id="mobile-menu-links" className="mobile-links">
                        <a href="https://ddtankarena.com/precadastro">Página Inicial</a>
                        <a href="apresentacao/index.html">Apresentação</a>
                        <a href="https://ddtankarena.com/downloads">Download</a>
                    </div>
                </header>

                <main>
                    <div className="main-content">
                        <img src="/img/novoservidor/imagens/logo.png" alt="DDTank Arena Logo" className="logo"></img>
                        <div className="countdown-container">
                            <h3 className="countdown-title">GRANDE LANÇAMENTO EM:</h3>
                            <div
                                id="countdown-timer">{timeLeft.days} dias {timeLeft.hours} horas {timeLeft.minutes} minutos
                                e {timeLeft.seconds} segundos
                            </div>
                        </div>
                    </div>

                    <div className="registration-box">
                        <div className="registration-header">
                            <h2>
                                Registre-se e garanta suas <span
                                className={"text-[#f06d09] font-bold"}>recompensas</span> de lançamento do novo
                                servidor do <br></br>
                                <span className={"text-[#f06d09] font-bold"}>DDTank Arena</span>
                            </h2>
                        </div>

                        <div className="registration-content">
                            <img src="/img/novoservidor/imagens/img0.png" alt="Imagem de Cadastro"
                                 className="registration-image"></img>
                            <form id="registrationForm">
                                <div className="form-group">
                                    <input type="email" id="email" required placeholder="Digite seu e-mail" name="email"
                                           value={form.email} onChange={handleChange}></input>
                                    <span className="error-message" id="email-error"></span>
                                </div>
                                <div className="form-group">
                                    <input type="tel" id="phone" required
                                           placeholder="Informe seu número de WhatsApp" name="telephone"
                                           value={form.telephone} onChange={handleChange}></input>
                                    <span className="error-message" id="phone-error"></span>
                                </div>
                                <button type="button" id="submit-button" onClick={rescue}>Resgatar</button>
                            </form>
                        </div>

                        <div className={`confirmation-box ${showConfirmBox ? "" : "hidden"}`} id="confirmationBox">
                            <button onClick={() => setShowConfirmBox(false)}
                                    className="close-button absolute top-2.5 right-2.5 bg-transparent border-none text-white text-xl cursor-pointer">&times;</button>
                            <div className="confirmation-header">
                                <h2>Parabéns!</h2>
                            </div>
                            <div className="confirmation-content">
                                <p>Você se registrou com sucesso. Em breve, você receberá uma mensagem com seu código
                                    exclusivo de pré-registro. Enquanto isso, acesse nosso </p>
                            </div>
                            <div className="confirmation-actions">
                                <a href="https://chat.whatsapp.com/G0bYZgbFnPV5LXTmiHYtEN" target="_blank"
                                   className="whatsapp-button">grupo oficial no WhatsApp</a>
                            </div>
                        </div>
                    </div>
                </main>

                <footer>
                    <p>&copy; 2024 Arena Digital Games. Todos os direitos reservados.</p>
                </footer>
            </div>


        </>
    );
};

export default NovoServidorRegister;