import { useNavigate } from 'react-router-dom';
import {useState} from "react";
import axios from "axios";
import Swal from "sweetalert2";

const SimpleLogin = () => {
    const navigate = useNavigate();

    const handleSignupClick = () => {
        navigate('/sign-up');
    };

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append("email", formData.email);
        formDataToSend.append("password", formData.password);

        Swal.showLoading();
        try {
            let response = await axios.post(`${process.env.REACT_APP_API_URL}v1/auth/login`, formDataToSend, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if(response.data.data.token)
            {
                localStorage.setItem("user", JSON.stringify(response.data.data.user));
                localStorage.setItem("token", response.data.data.token);
                localStorage.setItem("last_server", JSON.stringify(response.data.data.last_server));

                window.location = "/";
                //navigate(window.location.pathname + "?reload=" + new Date().getTime());
            }

            //await Swal.fire("", response.data.message, "success");
        } catch (err) {
            await Swal.fire("", err.response.data.message, "error");
        } finally {
            Swal.hideLoading();
        }
    };

    const handleResetPasswordClick = (e) => {
        e.preventDefault();

        navigate('/forgotPassword');
    };

    return (<div className="">
        <div className="flex items-center bg-[#67240e] text-white rounded-t-[30px] py-4 px-6">
            <img className="h-[40px] transform -scale-x-100" src="/img/character_3.png"
                 alt="login_character"/>
            <h2 className="text-xl text-[#ed9071] font-bold uppercase pl-4">Login</h2>
        </div>

        <form onSubmit={handleSubmit}>
            <div className="flex flex-col py-4 px-6">
                <div>
                    <label htmlFor="email" className="text-[#67240e] font-bold">Endereço de e-mail</label>
                    <input
                        className="focus:outline-none bg-[#67240e40] hover:bg-[#67240e69] w-full rounded-[15px] px-4 py-2 placeholder-[#67240e]"
                        id="email" name="email" type="text" placeholder="Insira seu e-mail" value={formData.email} onChange={handleChange} />
                </div>
                <div className="mt-4">
                    <label htmlFor="password" className="text-[#67240e] font-bold">Senha</label>
                    <input
                        className="focus:outline-none bg-[#67240e40] hover:bg-[#67240e69] w-full rounded-[15px] px-4 py-2 placeholder-[#67240e]"
                        id="password" name="password" type="password" placeholder="Senha" value={formData.password} onChange={handleChange} />
                    <div className="mt-2">
                        Esqueceu sua senha? <a href="#" className="text-[#670e0e] font-bold" onClick={handleResetPasswordClick}>Redefinir</a>
                    </div>
                </div>
            </div>

            <div className="flex justify-center gap-2 p-5">
                <button className="btn-brown-red rounded-[20px] text-white w-1/2">
                    Entrar
                </button>
                <button className="btn-brown-red rounded-[20px] text-white w-1/2" onClick={handleSignupClick}>
                    Cadastro
                </button>
            </div>
        </form>
    </div>);
};

export default SimpleLogin;