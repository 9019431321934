import SimpleHeader from "../../components/simple/simple-header";
import SimpleDashboardMenu from "../../components/simple/simple-dashboard-menu";
import SimpleFooter from "../../components/simple/simple-footer";
import axios from "axios";
import Swal from "sweetalert2";
import {useEffect, useState} from "react";
import SimpleLoadingComponent from "../../components/simple/simple-loading-component";
import {useNavigate} from "react-router-dom";

const DashboardDDTMoney = () => {
    const navigate = useNavigate();

    const [servers, setServers] = useState([]);
    const [characters, setCharacters] = useState([]);
    const [canBuy, setCanBuy] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectServer, setSelectedServer] = useState(-1);
    const [rechargeList, setRechargeList] = useState([]);
    const [selectedRecharge, setSelectedRecharge] = useState(-1);
    const [selectPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [selectCharacter, setSelectedCharacter] = useState(null);

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        }).format(value);
    };


    useEffect(() => {

        let fetchServerData = async () => {
            try {

                let serverListAxios = await axios.get(`${process.env.REACT_APP_API_URL}v1/server/list`);
                let serverListResponse = serverListAxios.data;

                setServers(serverListResponse.data.servers);
            } catch (err) {
                await Swal.fire("", "Error fetching data.", "error");
            }
        }

        fetchServerData();

    }, []);

    const handleServerSelect = (e) => {
        let selectedServer = parseInt(e.target.value);
        setSelectedServer(selectedServer);

        let fetchServerCharacters = async () => {
            Swal.showLoading();
            try {
                let serverCharacterAxios = await axios.get(
                    `${process.env.REACT_APP_API_URL}v1/logged/server/character-list?server_id=${selectedServer}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem("token")}`
                        }
                    }
                );

                //console.log(serverCharacterAxios.data.data.characters);
                setCharacters(serverCharacterAxios.data.data.characters);
            } catch (err) {
                await Swal.fire("", err.response.data.message, "error");
            } finally {
                Swal.close();
            }
            //console.log(serverCharacterAxios);
        };

        fetchServerCharacters();
    };

    const handleCharacterSelect = (e) => {
        let selectedCharacter = parseInt(e.target.value);
        setSelectedCharacter(selectedCharacter);

        let fetchRechargeCoupons = async () => {
            setIsLoading(true);
            try {

                let rechargeCouponsAxios = await axios.get(`${process.env.REACT_APP_API_URL}v1/server/recharge-coupons-list?server_id=${selectServer}`);
                let response = rechargeCouponsAxios.data.data;

                setRechargeList(response.rechargeCoupons);
                setCanBuy(true);
            } catch (err) {
                await Swal.fire("", err.response.data.message, "error");
            } finally {
                setIsLoading(false);
            }
        };

        fetchRechargeCoupons();
    };

    const handleSelectRechargeItem = (e) => {
        let rechargeItem = e.currentTarget;
        let rechargeId = rechargeItem.getAttribute("data-recharge-id");

        setSelectedRecharge(rechargeId);
    };

    const handleSelectPaymentMethod = (e) => {
        let paymentMethodItem = e.currentTarget;
        let rechargeMethod = paymentMethodItem.getAttribute("data-recharge-method");

        setSelectedPaymentMethod(rechargeMethod);
    };

    const handleFinishRecharge = async (e) => {
        e.preventDefault();

        Swal.showLoading();
        try {

            const formDataToSend = new FormData();
            formDataToSend.append("server_id", selectServer);
            formDataToSend.append("character_id", selectCharacter);
            formDataToSend.append("recharge_coupons_id", selectedRecharge);
            formDataToSend.append("method", selectPaymentMethod);

            let response = await axios.post(`${process.env.REACT_APP_API_URL}v1/logged/fast-recharge/create-order`, formDataToSend, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            });

            let crazyResponse = response.data.data;

            navigate("/dashboard/wait-payment", {
                state: {
                    paymentInfo: crazyResponse,
                    method: selectPaymentMethod
                }
            });

            switch(selectPaymentMethod) {
                case "picpay": {
                    window.open(crazyResponse.checkout.url, "_blank")
                    break;
                }
                case "mercadopago": {
                    window.open(crazyResponse.checkout.url, "_blank")
                    break;
                }
            }
            await Swal.fire("", "Continue na página aberta para realizar o pagamento!", "info")
        } catch (err) {
            await Swal.fire("", err.response.data.message, "error");
        } finally {
            Swal.hideLoading();
        }

    };

    return (
        <div className="flex-1 bg-[url('/public/img/bg.png')] bg-cover bg-center">
            <SimpleHeader></SimpleHeader>

            <main className="flex h-screen mx-auto justify-center gap-4 mt-20">
                <div className="flex-none">
                    <div className="flex flex-wrap gap-4">
                        <SimpleDashboardMenu></SimpleDashboardMenu>

                        <div className="flex-1 bg-white rounded-[60px] min-w-[700px] h-auto shadow-lg flex-shrink-0">

                            <div className="flex items-center bg-[#67240e] text-white rounded-t-[30px] py-4 px-6">
                                <img className="h-[40px]" src="/img/character_2.png" alt="notice_character"/>
                                <h2 className="text-xl text-[#ed9071] font-bold uppercase pl-4">Comprando Cupons</h2>
                            </div>

                            <div className="flex justify-center gap-4 px-4 py-4">
                                <div className="flex flex-col w-1/2">
                                    <label>Selecione o servidor</label>
                                    <select defaultValue="-1"
                                            className="focus:outline-none bg-[#67240e40] hover:bg-[#67240e69] w-full rounded-[15px] px-4 py-2 placeholder-[#67240e]" onChange={handleServerSelect}>
                                        <option value="-1" disabled>Lista de servidores</option>
                                        {servers.map(server => (
                                            <option key={server.id} value={server.id}>{server.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="flex flex-col w-1/2">
                                    <label>Selecione o personagem</label>
                                    <select defaultValue="-1"
                                            className="focus:outline-none bg-[#67240e40] hover:bg-[#67240e69] w-full rounded-[15px] px-4 py-2 placeholder-[#67240e]" onChange={handleCharacterSelect}>
                                        <option value="-1" disabled>Lista de personagens</option>
                                        {characters.map(character => (
                                            <option key={character.UserID} value={character.UserID}>{character.NickName}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            {
                                canBuy ? (
                                    <div>
                                        <div className="bg-[#67240e] w-full mb-2 py-[0.1px]"></div>

                                        <div className="flex flex-col px-4 py-4">
                                            <div className="flex flex-col mb-2 max-w-[700px]">
                                                <span className="text-2xl font-bold text-[#67240e]">Selecione o valor que deseja recarregar</span>
                                                <span className="break-words text-[#67240e]">Efetue o pagamento via Pix e receba seus cupons em até 5 minutos!</span>
                                                <span className="break-words text-[#67240e]">Além disso, ganhe 10% de bônus em cupons sobre o valor recarregado!</span>
                                            </div>
                                            <div className="grid grid-cols-3 gap-2">
                                                {rechargeList.map((recharge) => (
                                                    <div
                                                        className={`col-span-1 ${selectedRecharge == recharge.id ? "bg-[#937368]" : "bg-[#b19388]"} hover:bg-[#937368] border-[#855240] border-[2px] rounded-[15px] px-4 py-4 cursor-pointer`}
                                                        data-recharge-id={recharge.id}
                                                        onClick={handleSelectRechargeItem}
                                                    >
                                                        <div className="flex justify-between items-center">
                                                            <img src="/img/money.png" className="h-10" alt=""></img>
                                                            <div className="flex flex-col items-end">
                                                                <span
                                                                    className="text-[#67240e] font-bold font-sans">{recharge.value} Cupons</span>
                                                                <span
                                                                    className="text-[#67240e] font-bold font-sans">{formatCurrency(recharge.price / 100)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="bg-[#67240e] w-full mt-4 mb-2 py-[0.1px]"></div>

                                        <div className="flex flex-col px-4 py-4">
                                            <div className="flex flex-col mb-2 max-w-[700px]">
                                                <span className="text-2xl font-bold text-[#67240e]">Selecione sua forma de pagamento favorita</span>
                                            </div>

                                            <div className="grid grid-cols-3 gap-2">

                                                <div className={`col-span-1 ${selectPaymentMethod === "pix" ? "bg-[#95706340]" : "bg-[#95706321]"} hover:bg-[#95706340] border-[#855240] border-[2px] rounded-[15px] px-4 py-4 cursor-pointer`} data-recharge-method="pix" onClick={handleSelectPaymentMethod}>
                                                    <div className="flex justify-center items-center">
                                                        <img className="h-10" src="/img/pix-logo.png"
                                                             alt="Pague com Pix"></img>
                                                    </div>
                                                </div>

                                                <div className={`col-span-1 ${selectPaymentMethod === "mercadopago" ? "bg-[#95706340]" : "bg-[#95706321]"} hover:bg-[#95706340] border-[#855240] border-[2px] rounded-[15px] px-4 py-4 cursor-pointer`} data-recharge-method="mercadopago" onClick={handleSelectPaymentMethod}>
                                                    <div className="flex justify-center items-center">
                                                        <img className="h-10" src="/img/mercadopago-logo.png"
                                                             alt="Pague com Mercado Pago"></img>
                                                    </div>
                                                </div>

                                                <div className={`col-span-1 ${selectPaymentMethod === "picpay" ? "bg-[#95706340]" : "bg-[#95706321]"} hover:bg-[#95706340] border-[#855240] border-[2px] rounded-[15px] px-4 py-4 cursor-pointer`} data-recharge-method="picpay" onClick={handleSelectPaymentMethod}>
                                                    <div className="flex justify-center items-center">
                                                        <img className="h-10" src="/img/picpay-logo.png"
                                                             alt="Pague com PicPay"></img>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="bg-[#67240e] w-full mt-4 py-[0.1px]"></div>

                                        <div className="flex flex-col justify-center items-center h-[100px]">
                                            <div className="flex items-center">
                                                <input type="checkbox" id="checkbox"
                                                       className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"/>
                                                <label htmlFor="checkbox"
                                                       className="ml-2 text-sm font-medium text-gray-900">
                                                    Aceito os <a href="#" className="hover:text-[#ab4827]">termos e
                                                    condições</a> antes de realizar o pedido.
                                                </label>
                                            </div>

                                            <div className="mt-2">
                                                <button
                                                    className="hover:bg-[#77331d] bg-[#ab4827] text-white rounded-[15px] px-4 py-2" onClick={handleFinishRecharge}>Finalizar
                                                    Pedido
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex items-center justify-center">
                                        {
                                            isLoading ? (
                                                <SimpleLoadingComponent></SimpleLoadingComponent>
                                            ) : (<div></div>)
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </main>

            <SimpleFooter></SimpleFooter>
        </div>
    );
};

export default DashboardDDTMoney;