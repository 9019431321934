import LauncherMenuBtn from "../components/launcher/launcher-menu-btn";
import {useEffect} from "react";
import Swal from "sweetalert2";

const LauncherMenu = () => {
    const userInfo = JSON.parse(localStorage.getItem("user") ?? null);

    return (
        <div
            className="flex flex-col bg-[#b5411f] text-white px-6 py-2 mb-1 mx-2 border-[#752711] border-2 rounded-[20px]">

            {
                !userInfo ? (
                    <div className="flex justify-center">
                        <div className="flex gap-1">
                            <div className="flex gap-1">
                                <LauncherMenuBtn label="Entrar" path="/launcher/login"></LauncherMenuBtn>
                                <LauncherMenuBtn label="Cadastrar" path="/launcher/register"></LauncherMenuBtn>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="flex justify-between">
                        <div className="flex gap-1">
                            <LauncherMenuBtn label="Seleção de servidor" path="/launcher/logged"></LauncherMenuBtn>
                            <LauncherMenuBtn label="Configurações" path=""></LauncherMenuBtn>
                        </div>

                        <div className="flex gap-1">
                            <LauncherMenuBtn label="Sair" path="logout"></LauncherMenuBtn>
                        </div>
                    </div>
                )
            }

        </div>
    );
};

export default LauncherMenu;