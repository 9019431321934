import {useEffect, useState} from "react";
import axios, {Axios} from "axios";

const WebTest = () => {
    const [name, setName] = useState("");
    const [applicationData, setApplicationData] = useState([]);

    const fetchByName = () => {
        const fetchApplication = async () => {
            let response = await axios.get(`https://api.nationalize.io/?name=${name}`);

            setApplicationData(response.data);
        };

        fetchApplication();
    };

    const handleSearchByName = (event) => {
        setName(event.target.value);
    };

    return (
        <div className="flex flex-col justify-center items-center mt-4">
            <div>Quantidade de pessoas: {applicationData.count}</div>
            <div>Nome pesquisado: {applicationData.name}</div>

            <div className={"flex items-center justify-center bg-blue-700 h-[200px] w-[700px] py-4 px-4 mt-10"}>
                <input placeholder="Busque pelo nome" className={"bg-white px-4 py-4"} onChange={handleSearchByName} />
                <button className="bg-yellow-600 px-4 py-4 text-white font-bold" onClick={fetchByName}>Pesquisar</button>
            </div>
        </div>

    );
};

export default WebTest;