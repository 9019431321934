import LauncherMenu from "./launcher-menu";
import {useEffect, useState} from "react";
import axios from "axios";
import Swal from "sweetalert2";

const LauncherLoggedHome = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const [servers, setServers] = useState([]);

    useEffect(() => {
        const fetchServers = async () => {
            try {
                let serverListAxios = await axios.get(`${process.env.REACT_APP_API_URL}v1/server/list`, {
                    headers: {
                        'Content-Type': 'application/json' // Adicionando o Content-Type
                    }
                });

               setServers(serverListAxios.data.data.servers);
            } catch (err) {
                await Swal.fire("", "Error fetching servers.", "error");
            }
        };

        fetchServers();
    }, []);

    const openGame = () => {
        window.jsInterop.handleOpenGameCall("aaa");
    };

    return (
        <div className="bg-[#732f1c]">
            <div
                className="flex flex-col bg-[url('/public/img/bg.png')] bg-cover bg-center mx-auto h-screen rounded overflow-y-hidden">
                <div className="flex-grow">
                    <div className="flex items-center justify-center h-full">

                        <div className="w-[450px]">
                            <div className="flex items-center bg-[#67240e] text-white rounded-t-[20px] py-4 px-6">
                                <img className="h-[40px] transform -scale-x-100" src="/img/character_3.png"
                                     alt="login_character"/>
                                <h2 className="text-xl text-[#ed9071] font-bold pl-4">Olá, {user.name}!</h2>
                            </div>

                            <div className="flex flex-col bg-white h-[150px] rounded-b-[20px] px-4 py-2">
                                <div className="flex flex-col mt-2">
                                    <span className="text-[#916151] font-sans font-bold py-1">Lista de servidores:</span>

                                    <select
                                        className="bg-[#916151] text-white px-4 py-2 border border-[#7c4a48] focus:outline-none focus:ring-2 focus:ring-[#ffccbc]"
                                    >
                                        <option value="" disabled selected className="text-gray-400">Selecione para
                                            jogar
                                        </option>
                                        {servers.map(server => (
                                            <option key={server.id} value={server.id}>{server.name}</option>
                                        ))}
                                    </select>

                                    <div className="flex mt-1 gap-[1px]">
                                        <button className="btn-brown-red px-1 py-2 rounded-b-[20px] rounded-r-[0px] text-white w-1/2" onClick={openGame}>
                                            Jogar
                                        </button>

                                        <button className="btn-brown-red px-1 py-2 rounded-b-[20px] rounded-l-[0px] text-white w-1/2">
                                            Recarregar Cupons
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <LauncherMenu></LauncherMenu>
            </div>
        </div>
    );
};

export default LauncherLoggedHome;