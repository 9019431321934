import {useLocation, useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import {useEffect, useState} from "react";

const LauncherMenuBtn = (props) => {
    const [isActive, setIsActive] = useState(false);

    const location = useLocation();

    const currentRoute = location.pathname;
    useEffect(() => {
        if(currentRoute.toString() === props.path.toString()) {
            setIsActive(true);
        }
    }, []);

    const navigate = useNavigate();

    const handleChangePage = (e) => {
        e.preventDefault();

        if(props.path.toString() === "logout") {
            localStorage.removeItem("user");
            localStorage.removeItem("token");

            window.location = "/launcher/login";
            return;
        }

        navigate(props.path);
    };

    return (
        <button className={`${!isActive ? "bg-[#8d361d]" : "bg-[#752b16]"} border-[#752711] border-2 hover:bg-[#752b16] text-[#ed9e87] px-4 py-2 rounded-full`} type={"button"} onClick={handleChangePage}>{props.label}</button>
    );
};

export default LauncherMenuBtn;