import {useLocation} from "react-router-dom";

const SimplePaymentCompleted = () => {
    const location = useLocation();
    const { paymentInfo, method } = location.state || {};

    return (
        <div className="flex-1 bg-white rounded-[60px] min-w-[700px] h-[400px] shadow-lg flex-shrink-0">

            <div className="flex items-center bg-[#67240e] text-white rounded-t-[30px] py-4 px-6">
                <img className="h-[40px]" src="/img/character_2.png" alt="notice_character"/>
                <h2 className="text-xl text-[#ed9071] font-bold uppercase pl-4">Pagamento realizado com sucesso!</h2>
            </div>

            <div className="flex justify-center py-10">
                <div className="flex justify-between items-center">
                    <div>
                        <img src="/img/buy/porchase_completed.png" alt="Aguardando pagamento"></img>
                    </div>
                    <div className="flex flex-col justify-center max-w-[500px]">
                         <span className="text-2xl px-2 text-[#ab5858] font-bold">Pagamento identificado!</span>
                        <span className="px-2 text-[#ab5858] break-words">Os cupons estão a caminho da sua conta e em breve estarão disponíveis para você!</span>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default SimplePaymentCompleted;